@import "~bootstrap/dist/css/bootstrap.min.css";
// @import "~owl.carousel/dist/assets/owl.carousel.min.css";
// @import "~owl.carousel/dist/assets/owl.theme.default.min.css";
@import "~ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";
//@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "./assets/css/glyphiconFont.css";
@import "./assets/scss/layout/_alex.material.theme.scss";

/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/base/_variables.scss";
@import "./assets/scss/base/_mixins.scss";
@import "./assets/scss/base/_reset.scss";
@import "./assets/scss/base/_base.scss";

//bootstrap 4 compatibility

.affix {    
     position:fixed;
   }

   .rightMenuSection{
    margin-right:10px;
}
.modal-open .modal {
  overflow-y: hidden !important;
}