@import '../base/_variables.scss';


@import '~@angular/material/_theming';
@import './_themingAlex';


@include mat-core();
$alex-app-primary: mat-palette($mat-ocean-blue,50);
$alex-app-accent:  mat-palette($mat-ocean-blue, A200, A100, A400);
$alex-app-warn:    mat-palette($mat-red);

$alex-app-theme: mat-light-theme($alex-app-primary, $alex-app-accent, $alex-app-warn);

@include angular-material-theme($alex-app-theme);

.tabType1
{ 
      font-family:Arial!important;
      .mat-tab-header{
          border:$borderNone;
        
      }

    .mat-tab-body-content
    {
      overflow:hidden!important;
    }
      
      .mat-ink-bar{
      height:4px !important;
  }
  .mat-tab-label-container .mat-tab-label{
    font-size: $fontsize14;
    font-weight: $fontbold;
    text-align: center;
    color: $darkisGray;
    height:40px;
    line-height:35px;
    font-family:Arial;
    margin-right:5px;
  }
  .mat-tab-label:hover {

      border-bottom:4px solid $oceanBlue;
      opacity: 1;
      color:$grey6;
  } 
  .mat-tab-label:focus{background:none;} 


}
.homeTab .mat-tab-header{margin-bottom:40px;}

@media screen and (max-width:991px)
{

.homeTab .mat-tab-header{margin-bottom:20px;}
}


