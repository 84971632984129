
@font-face {
    font-family: 'KPMG';
    src:  url('../fonts/kpmg/KPMG-Light.eot');
    src: url('../fonts/kpmg/KPMG-Light.eot?#iefix') format('eot'),
    url('../fonts/kpmg/KPMG-Light.woff2') format('woff2'),
    url('../fonts/kpmg/KPMG-Light.woff') format('woff'),
    url('../fonts/kpmg/KPMG-Light.ttf') format('truetype'),
    url('../fonts/kpmg/KPMG-Light.svg') format('svg');
    font-style: normal;
    font-weight: 300;
}
