html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
address,
code,
img,
sub,
b,
i,
dl,
dt,
dd,
ol,
fieldset,
form,
label,
caption {
  border: 0;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  font-weight: $fontnormal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: normal;
}
ul {
  margin: 0;
}

span b {
  font-weight: $fontbold !important;
}

div b {
  width: 100%;
  font-weight: 600;
}
ul:not([_alexDocumentViewer]),
li:not([_alexDocumentViewer]) {
  list-style: none;
  padding: 0;
}
td[_alexDocumentViewer] {
  padding: 3px;
}

body {
  background-color: #f0f0f0;
  color: $bodyFontcolor;
  font-family: $bodyFont;
  font-size: $bodyFontsize;
  font-weight: $fontnormal;
}
.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}

panel-body .ALexUIDisplayHeading3LightBlue {
  display: block;
}

th {
  padding: 18px 0px 18px 0px;
}

a {
  text-decoration: $textdecor;
  outline: none !important;
  // cursor: pointer;
  color: $oceanBlue;
}

a img {
  border: none;
}

a:hover {
  color: $colorBlack;
  text-decoration: $textdecor;
}
a:focus {
  outline: none;
  text-decoration: $textdecor;
}

:focus {
  outline: none;
  text-decoration: $textdecor;
}
input::-ms-clear {
  display: none;
}
body {
  -ms-overflow-style: scrollbar;
}

html {
  overflow-x: hidden;
}
