$bodyFont:Arial;
$kpmgFont:KPMG;
$fontsize11:11px;
$fontsize12:12px;
$bodyFontsize:13px;
$fontsize14:14px;
$fontsize15:15px;
$fontsize16:16px;
$fontsize17:17px;
$fontsize18:18px;
$fontsize19:19px;
$fontsize20:20px;
$fontsize21:21px;
$fontsize24:24px;
$fontsize42:42px;
$fontsize54:54px;
$fontsize64:64px;
$fontnormal:normal;
$fontbold:bold;
$font300:300;
$textdecor:none;
$padding0:0px;
$margin0:0px;
$floatLeft:left;
$floatRt:right;
$positionAbs:absolute;
$positionRel:relative;
$bgNone:none;
$borderNone:none;
$floatNone:none;
$posRight:0px;
$posLeft:0;
$padding15:15px;
$padding5:5px;
$padding8:8px;
$padding10:10px;
$alignCenter:center;
$alignRight:right;
$alignLeft:left;
$marginAuto:0px auto;
$bgtransparent:transparent;

/**color*****/
$fontDeepgrey:#444444;
$bodyFontcolor:#5b5b5b;
$colorBlack:#000;
$colorWhite:#fff;
$whiteSmoke:#f5f5f5;
$fontcolorgrey:#acacac;
$oceanBlue:#005EB8;  /***kpmg blue medium****/
$twilightBlueTwo:#093b76;
$twilightBlue:#0f4d97;
$darkishBlue:#00338d; /***kpmg blue dark****/
$ceruleanBlue:#0091da;
$skyBlue:#0076b1;
$darkishPurple:#6D2077;
$lipstick:#bc204b;
$lightSky:#98cdff;
$offWhite:#f9f9f9;
$darkTwo:#212c3a;
$duckEggBlue:#e8f5fb;
$menuhover:#ebebeb;
$steelgray:#74808d;
$darkisGray:#222222;
$grey1:#b2b9c2;
$grey2:#acacac;
$grey3:#929292;
$grey4:#d7d7d7;
$grey5:#d8d8d8;
$grey6:#747474;
$grey7:#cad1da;
$grey8:#c6c6c6;
$grey9:#b8b8b8;
$grey10:#dddddd;
$lightBlack:#4e5763;
$charcoalgrey:#39424c;
$steelgrey:#74808d;
$deepGreen:#31718f;
$lightgrey:#97a0aa;
$greenish:#00a3a1;
$deepgreen:#009A44;
$deepRed:#BC1F4B;
$lightblue:#0091DA;
$lightorange:#F78D2E;
$yellowOrange:#eaaa00;
$yellow:#fbef5a;
$deepyellow:#bf8d00;
$blackbar:#14212F;
$darkThree:#15212f;
$slightBlack:#2a2a2a;
$borderColor1:#d9dee5;
$tealBlue:#00a3a1;
$eggBlueTwo:#d6e7f7;
$grass:#43b02a;
$searchHit: #05215B;

$containerPaddMain:0 18px;
$containerPadd:0 15px;
// $contentArea:15px 15px 40px 15px;
$contentArea:0px;
$topPadding:20px 0;
$topMargin:14px 0 16px 0;

$borderRadius:0px;
$fontLineHeight:38px;
$Width:100%;
$ValingMid:middle;
$cursonPointer:pointer; 
$searchHeight:28px;


