// Import all the theming functionality.
// Core styles that can be used to apply material design treatments to any element.
@mixin cdk-a11y {
  .cdk-visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    text-transform: none;
    width: 1px;
  }
}

/**
 * Applies styles for users in high contrast mode. Note that this only applies
 * to Microsoft browsers. Chrome can be included by checking for the `html[hc]`
 * attribute, however Chrome handles high contrast differently.
 */
@mixin cdk-high-contrast {
  @media screen and (-ms-high-contrast: active) {
    @content;
  }
}

// Media queries
// TODO: Find a way to respect media query ranges.
// TODO: For example the xs-breakpoint should not interfere with the sm-breakpoint.
$mat-xsmall: 'max-width: 600px';
$mat-small: 'max-width: 960px';

// TODO(crisbeto): this isn't being used anywhere within Material. keeping for backwards compat.
$mat-font-family:Arial !default;

// TODO: Revisit all z-indices before beta
// z-index master list

$z-index-fab: 20 !default;
$z-index-drawer: 100 !default;

// Overlay z indices.

// We want overlays to always appear over user content, so set a baseline
// very high z-index for the overlay container, which is where we create the new
// stacking context for all overlays.
$cdk-z-index-overlay-container: 1000;
$cdk-z-index-overlay: 1000;
$cdk-z-index-overlay-backdrop: 1000;

// Background color for all of the backdrops
$cdk-overlay-dark-backdrop-background: rgba(0, 0, 0, 0.6);

// Global constants
$pi: 3.14159265;

// Padding between input toggles and their labels
$mat-toggle-padding: 8px !default;
// Width and height of input toggles
$mat-toggle-size: 20px !default;

// Easing Curves
// TODO(jelbourn): all of these need to be revisited

// The default animation curves used by material design.
$mat-linear-out-slow-in-timing-function: cubic-bezier(0, 0, 0.2, 0.1) !default;
$mat-fast-out-slow-in-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !default;
$mat-fast-out-linear-in-timing-function: cubic-bezier(0.4, 0, 1, 1) !default;

$ease-in-out-curve-function: cubic-bezier(0.35, 0, 0.25, 1) !default;

$swift-ease-out-duration: 400ms !default;
$swift-ease-out-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1) !default;
$swift-ease-out: all $swift-ease-out-duration $swift-ease-out-timing-function !default;

$swift-ease-in-duration: 300ms !default;
$swift-ease-in-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2) !default;
$swift-ease-in: all $swift-ease-in-duration $swift-ease-in-timing-function !default;

$swift-ease-in-out-duration: 500ms !default;
$swift-ease-in-out-timing-function: $ease-in-out-curve-function !default;
$swift-ease-in-out: all $swift-ease-in-out-duration $swift-ease-in-out-timing-function !default;

$swift-linear-duration: 80ms !default;
$swift-linear-timing-function: linear !default;
$swift-linear: all $swift-linear-duration $swift-linear-timing-function !default;



// A collection of mixins and CSS classes that can be used to apply elevation to a material
// element.
// See: https://www.google.com/design/spec/what-is-material/elevation-shadows.html
// Examples:
//
//
// .mat-foo {
//   @include $mat-elevation(2);
//
//   &:active {
//     @include $mat-elevation(8);
//   }
// }
//
// <div id="external-card" class="mat-elevation-z2"><p>Some content</p></div>
//
// For an explanation of the design behind how elevation is implemented, see the design doc at
// https://goo.gl/Kq0k9Z.

// Colors for umbra, penumbra, and ambient shadows. As described in the design doc, each elevation
// level is created using a set of 3 shadow values, one for umbra (the shadow representing the
// space completely obscured by an object relative to its light source), one for penumbra (the
// space partially obscured by an object), and one for ambient (the space which contains the object
// itself). For a further explanation of these terms and their meanings, see
// https://en.wikipedia.org/wiki/Umbra,_penumbra_and_antumbra.

$_umbra-color: rgba(black, 0.2);
$_penumbra-color: rgba(black, 0.14);
$_ambient-color: rgba(black, 0.12);

// Maps for the different shadow sets and their values within each z-space. These values were
// created by taking a few reference shadow sets created by Google's Designers and interpolating
// all of the values between them.

$_umbra-elevation-map: (
  0: '0px 0px 0px 0px #{$_umbra-color}',
  1: '0px 2px 1px -1px #{$_umbra-color}',
  2: '0px 3px 1px -2px #{$_umbra-color}',
  3: '0px 3px 3px -2px #{$_umbra-color}',
  4: '0px 2px 4px -1px #{$_umbra-color}',
  5: '0px 3px 5px -1px #{$_umbra-color}',
  6: '0px 3px 5px -1px #{$_umbra-color}',
  7: '0px 4px 5px -2px #{$_umbra-color}',
  8: '0px 5px 5px -3px #{$_umbra-color}',
  9: '0px 5px 6px -3px #{$_umbra-color}',
  10: '0px 6px 6px -3px #{$_umbra-color}',
  11: '0px 6px 7px -4px #{$_umbra-color}',
  12: '0px 7px 8px -4px #{$_umbra-color}',
  13: '0px 7px 8px -4px #{$_umbra-color}',
  14: '0px 7px 9px -4px #{$_umbra-color}',
  15: '0px 8px 9px -5px #{$_umbra-color}',
  16: '0px 8px 10px -5px #{$_umbra-color}',
  17: '0px 8px 11px -5px #{$_umbra-color}',
  18: '0px 9px 11px -5px #{$_umbra-color}',
  19: '0px 9px 12px -6px #{$_umbra-color}',
  20: '0px 10px 13px -6px #{$_umbra-color}',
  21: '0px 10px 13px -6px #{$_umbra-color}',
  22: '0px 10px 14px -6px #{$_umbra-color}',
  23: '0px 11px 14px -7px #{$_umbra-color}',
  24: '0px 11px 15px -7px #{$_umbra-color}'
);

$_penumbra-elevation-map: (
  0: '0px 0px 0px 0px #{$_penumbra-color}',
  1: '0px 1px 1px 0px #{$_penumbra-color}',
  2: '0px 2px 2px 0px #{$_penumbra-color}',
  3: '0px 3px 4px 0px #{$_penumbra-color}',
  4: '0px 4px 5px 0px #{$_penumbra-color}',
  5: '0px 5px 8px 0px #{$_penumbra-color}',
  6: '0px 6px 10px 0px #{$_penumbra-color}',
  7: '0px 7px 10px 1px #{$_penumbra-color}',
  8: '0px 8px 10px 1px #{$_penumbra-color}',
  9: '0px 9px 12px 1px #{$_penumbra-color}',
  10: '0px 10px 14px 1px #{$_penumbra-color}',
  11: '0px 11px 15px 1px #{$_penumbra-color}',
  12: '0px 12px 17px 2px #{$_penumbra-color}',
  13: '0px 13px 19px 2px #{$_penumbra-color}',
  14: '0px 14px 21px 2px #{$_penumbra-color}',
  15: '0px 15px 22px 2px #{$_penumbra-color}',
  16: '0px 16px 24px 2px #{$_penumbra-color}',
  17: '0px 17px 26px 2px #{$_penumbra-color}',
  18: '0px 18px 28px 2px #{$_penumbra-color}',
  19: '0px 19px 29px 2px #{$_penumbra-color}',
  20: '0px 20px 31px 3px #{$_penumbra-color}',
  21: '0px 21px 33px 3px #{$_penumbra-color}',
  22: '0px 22px 35px 3px #{$_penumbra-color}',
  23: '0px 23px 36px 3px #{$_penumbra-color}',
  24: '0px 24px 38px 3px #{$_penumbra-color}'
);

$_ambient-elevation-map: (
  0: '0px 0px 0px 0px #{$_ambient-color}',
  1: '0px 1px 3px 0px #{$_ambient-color}',
  2: '0px 1px 5px 0px #{$_ambient-color}',
  3: '0px 1px 8px 0px #{$_ambient-color}',
  4: '0px 1px 10px 0px #{$_ambient-color}',
  5: '0px 1px 14px 0px #{$_ambient-color}',
  6: '0px 1px 18px 0px #{$_ambient-color}',
  7: '0px 2px 16px 1px #{$_ambient-color}',
  8: '0px 3px 14px 2px #{$_ambient-color}',
  9: '0px 3px 16px 2px #{$_ambient-color}',
  10: '0px 4px 18px 3px #{$_ambient-color}',
  11: '0px 4px 20px 3px #{$_ambient-color}',
  12: '0px 5px 22px 4px #{$_ambient-color}',
  13: '0px 5px 24px 4px #{$_ambient-color}',
  14: '0px 5px 26px 4px #{$_ambient-color}',
  15: '0px 6px 28px 5px #{$_ambient-color}',
  16: '0px 6px 30px 5px #{$_ambient-color}',
  17: '0px 6px 32px 5px #{$_ambient-color}',
  18: '0px 7px 34px 6px #{$_ambient-color}',
  19: '0px 7px 36px 6px #{$_ambient-color}',
  20: '0px 8px 38px 7px #{$_ambient-color}',
  21: '0px 8px 40px 7px #{$_ambient-color}',
  22: '0px 8px 42px 7px #{$_ambient-color}',
  23: '0px 9px 44px 8px #{$_ambient-color}',
  24: '0px 9px 46px 8px #{$_ambient-color}'
);


// The default duration value for elevation transitions.
$mat-elevation-transition-duration: 280ms !default;

// The default easing value for elevation transitions.
$mat-elevation-transition-timing-function: $mat-fast-out-slow-in-timing-function;

// Applies the correct css rules to an element to give it the elevation specified by $zValue.
// The $zValue must be between 0 and 24.
@mixin mat-elevation($zValue) {
  @if type-of($zValue) != number or not unitless($zValue) {
    @error '$zValue must be a unitless number';
  }
  @if $zValue < 0 or $zValue > 24 {
    @error '$zValue must be between 0 and 24';
  }

  box-shadow: #{map-get($_umbra-elevation-map, $zValue)},
              #{map-get($_penumbra-elevation-map, $zValue)},
              #{map-get($_ambient-elevation-map, $zValue)};
}

// Returns a string that can be used as the value for a transition property for elevation.
// Calling this function directly is useful in situations where a component needs to transition
// more than one property.
//
// .foo {
//   transition: mat-elevation-transition-property-value(), opacity 100ms ease;
// }
@function mat-elevation-transition-property-value(
    $duration: $mat-elevation-transition-duration,
    $easing: $mat-elevation-transition-timing-function) {
  @return box-shadow #{$duration} #{$easing};
}

// Applies the correct css rules needed to have an element transition between elevations.
// This mixin should be applied to elements whose elevation values will change depending on their
// context (e.g. when active or disabled).
//
// NOTE(traviskaufman): Both this mixin and the above function use default parameters so they can
// be used in the same way by clients.
@mixin mat-elevation-transition(
    $duration: $mat-elevation-transition-duration,
    $easing: $mat-elevation-transition-timing-function) {
  transition: mat-elevation-transition-property-value($duration, $easing);
}




@mixin cdk-overlay() {
  .cdk-overlay-container, .cdk-global-overlay-wrapper {
    // Disable events from being captured on the overlay container.
    pointer-events: none;

    // The container should be the size of the viewport.
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  // The overlay-container is an invisible element which contains all individual overlays.
  .cdk-overlay-container {
    position: fixed;
    z-index: $cdk-z-index-overlay-container;
  }

  // We use an extra wrapper element in order to use make the overlay itself a flex item.
  // This makes centering the overlay easy without running into the subpixel rendering
  // problems tied to using `transform` and without interfering with the other position
  // strategies.
  .cdk-global-overlay-wrapper {
    display: flex;
    position: absolute;
    z-index: $cdk-z-index-overlay;
  }

  // A single overlay pane.
  .cdk-overlay-pane {
    position: absolute;
    pointer-events: auto;
    box-sizing: border-box;
    z-index: $cdk-z-index-overlay;
  }

  .cdk-overlay-backdrop {
    // TODO(jelbourn): reuse sidenav fullscreen mixin.
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: $cdk-z-index-overlay-backdrop;
    pointer-events: auto;

    // TODO(jelbourn): figure out if there are actually spec'ed colors for both light and dark
    // themes here. Currently using the values from AngularJS Material.
    transition: opacity $swift-ease-out-duration $swift-ease-out-timing-function;
    opacity: 0;

    &.cdk-overlay-backdrop-showing {
      opacity: 0.48;
    }
  }

  .cdk-overlay-dark-backdrop {
    background: $cdk-overlay-dark-backdrop-background;
  }

  .cdk-overlay-transparent-backdrop {
    background: none;
  }

  // Used when disabling global scrolling.
  .cdk-global-scrollblock {
    position: fixed;

    // Necessary for the content not to lose its width. Note that we're using 100%, instead of
    // 100vw, because 100vw includes the width plus the scrollbar, whereas 100% is the width
    // that the element had before we made it `fixed`.
    width: 100%;

    // Note: this will always add a scrollbar to whatever element it is on, which can
    // potentially result in double scrollbars. It shouldn't be an issue, because we won't
    // block scrolling on a page that doesn't have a scrollbar in the first place.
    overflow-y: scroll;
  }
}

// Color palettes from the Material Design spec.
// See https://www.google.com/design/spec/style/color.html
//
// Contrast colors are hard-coded because it is too difficult (probably impossible) to
// calculate them. These contrast colors are pulled from the public Material Design spec swatches.
// While the contrast colors in the spec are not prescriptive, we use them for convenience.


$black-87-opacity: rgba(black, 0.87);
$white-87-opacity: rgba(white, 0.87);
$black-12-opacity: rgba(black, 0.12);
$white-12-opacity: rgba(white, 0.12);
$black-6-opacity: rgba(black, 0.06);
$white-6-opacity: rgba(white, 0.06);

$mat-red: (
  50: #ffebee,
  100: #ffcdd2,
  200: #ef9a9a,
  300: #e57373,
  400: #ef5350,
  500: #f44336,
  600: #e53935,
  700: #d32f2f,
  800: #c62828,
  900: #b71c1c,
  A100: #ff8a80,
  A200: #ff5252,
  A400: #ff1744,
  A700: #d50000,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: white,
    A400: white,
    A700: white,
  )
);

$mat-pink: (
  50: #fce4ec,
  100: #f8bbd0,
  200: #f48fb1,
  300: #f06292,
  400: #ec407a,
  500: #e91e63,
  600: #d81b60,
  700: #c2185b,
  800: #ad1457,
  900: #880e4f,
  A100: #ff80ab,
  A200: #ff4081,
  A400: #f50057,
  A700: #c51162,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: $white-87-opacity,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: white,
    A400: white,
    A700: white,
  )
);

$mat-purple: (
  50: #f3e5f5,
  100: #e1bee7,
  200: #ce93d8,
  300: #ba68c8,
  400: #ab47bc,
  500: #9c27b0,
  600: #8e24aa,
  700: #7b1fa2,
  800: #6a1b9a,
  900: #4a148c,
  A100: #ea80fc,
  A200: #e040fb,
  A400: #d500f9,
  A700: #aa00ff,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: white,
    400: white,
    500: $white-87-opacity,
    600: $white-87-opacity,
    700: $white-87-opacity,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: white,
    A400: white,
    A700: white,
  )
);

$mat-deep-purple: (
  50: #ede7f6,
  100: #d1c4e9,
  200: #b39ddb,
  300: #9575cd,
  400: #7e57c2,
  500: #673ab7,
  600: #5e35b1,
  700: #512da8,
  800: #4527a0,
  900: #311b92,
  A100: #b388ff,
  A200: #7c4dff,
  A400: #651fff,
  A700: #6200ea,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: white,
    400: white,
    500: $white-87-opacity,
    600: $white-87-opacity,
    700: $white-87-opacity,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: white,
    A400: $white-87-opacity,
    A700: $white-87-opacity,
  )
);

$mat-indigo: (
  50: #e8eaf6,
  100: #c5cae9,
  200: #9fa8da,
  300: #7986cb,
  400: #5c6bc0,
  500: #3f51b5,
  600: #3949ab,
  700: #303f9f,
  800: #283593,
  900: #1a237e,
  A100: #8c9eff,
  A200: #536dfe,
  A400: #3d5afe,
  A700: #304ffe,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: white,
    400: white,
    500: $white-87-opacity,
    600: $white-87-opacity,
    700: $white-87-opacity,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: white,
    A400: white,
    A700: $white-87-opacity,
  )
);

$mat-blue: (
  50: #e3f2fd,
  100: #bbdefb,
  200: #90caf9,
  300: #64b5f6,
  400: #42a5f5,
  500: #2196f3,
  600: #1e88e5,
  700: #1976d2,
  800: #1565c0,
  900: #0d47a1,
  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: white,
    A400: white,
    A700: white,
  )
);

$mat-ocean-blue: (
  50: #005eb8,
  100: #bbdefb,
  200: #90caf9,
  300: #64b5f6,
  400: #42a5f5,
  500: #2196f3,
  600: #1e88e5,
  700: #1976d2,
  800: #1565c0,
  900: #0d47a1,
  A100: #82b1ff,
  A200: #448aff,
  A400: #2979ff,
  A700: #2962ff,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: white,
    A400: white,
    A700: white,
  )
);
$mat-light-blue: (
  50: #e1f5fe,
  100: #b3e5fc,
  200: #81d4fa,
  300: #4fc3f7,
  400: #29b6f6,
  500: #03a9f4,
  600: #039be5,
  700: #0288d1,
  800: #0277bd,
  900: #01579b,
  A100: #80d8ff,
  A200: #40c4ff,
  A400: #00b0ff,
  A700: #0091ea,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: white,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: white,
  )
);

$mat-cyan: (
  50: #e0f7fa,
  100: #b2ebf2,
  200: #80deea,
  300: #4dd0e1,
  400: #26c6da,
  500: #00bcd4,
  600: #00acc1,
  700: #0097a7,
  800: #00838f,
  900: #006064,
  A100: #84ffff,
  A200: #18ffff,
  A400: #00e5ff,
  A700: #00b8d4,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: white,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: $black-87-opacity,
  )
);

$mat-teal: (
  50: #e0f2f1,
  100: #b2dfdb,
  200: #80cbc4,
  300: #4db6ac,
  400: #26a69a,
  500: #009688,
  600: #00897b,
  700: #00796b,
  800: #00695c,
  900: #004d40,
  A100: #a7ffeb,
  A200: #64ffda,
  A400: #1de9b6,
  A700: #00bfa5,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: $black-87-opacity,
  )
);

$mat-green: (
  50: #e8f5e9,
  100: #c8e6c9,
  200: #a5d6a7,
  300: #81c784,
  400: #66bb6a,
  500: #4caf50,
  600: #43a047,
  700: #388e3c,
  800: #2e7d32,
  900: #1b5e20,
  A100: #b9f6ca,
  A200: #69f0ae,
  A400: #00e676,
  A700: #00c853,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: $black-87-opacity,
  )
);

$mat-light-green: (
  50: #f1f8e9,
  100: #dcedc8,
  200: #c5e1a5,
  300: #aed581,
  400: #9ccc65,
  500: #8bc34a,
  600: #7cb342,
  700: #689f38,
  800: #558b2f,
  900: #33691e,
  A100: #ccff90,
  A200: #b2ff59,
  A400: #76ff03,
  A700: #64dd17,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: $black-87-opacity,
    600: $black-87-opacity,
    700: $black-87-opacity,
    800: white,
    900: white,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: $black-87-opacity,
  )
);

$mat-lime: (
  50: #f9fbe7,
  100: #f0f4c3,
  200: #e6ee9c,
  300: #dce775,
  400: #d4e157,
  500: #cddc39,
  600: #c0ca33,
  700: #afb42b,
  800: #9e9d24,
  900: #827717,
  A100: #f4ff81,
  A200: #eeff41,
  A400: #c6ff00,
  A700: #aeea00,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: $black-87-opacity,
    600: $black-87-opacity,
    700: $black-87-opacity,
    800: $black-87-opacity,
    900: white,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: $black-87-opacity,
  )
);

$mat-yellow: (
  50: #fffde7,
  100: #fff9c4,
  200: #fff59d,
  300: #fff176,
  400: #ffee58,
  500: #ffeb3b,
  600: #fdd835,
  700: #fbc02d,
  800: #f9a825,
  900: #f57f17,
  A100: #ffff8d,
  A200: #ffff00,
  A400: #ffea00,
  A700: #ffd600,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: $black-87-opacity,
    600: $black-87-opacity,
    700: $black-87-opacity,
    800: $black-87-opacity,
    900: $black-87-opacity,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: $black-87-opacity,
  )
);

$mat-amber: (
  50: #fff8e1,
  100: #ffecb3,
  200: #ffe082,
  300: #ffd54f,
  400: #ffca28,
  500: #ffc107,
  600: #ffb300,
  700: #ffa000,
  800: #ff8f00,
  900: #ff6f00,
  A100: #ffe57f,
  A200: #ffd740,
  A400: #ffc400,
  A700: #ffab00,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: $black-87-opacity,
    600: $black-87-opacity,
    700: $black-87-opacity,
    800: $black-87-opacity,
    900: $black-87-opacity,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: $black-87-opacity,
  )
);

$mat-orange: (
  50: #fff3e0,
  100: #ffe0b2,
  200: #ffcc80,
  300: #ffb74d,
  400: #ffa726,
  500: #ff9800,
  600: #fb8c00,
  700: #f57c00,
  800: #ef6c00,
  900: #e65100,
  A100: #ffd180,
  A200: #ffab40,
  A400: #ff9100,
  A700: #ff6d00,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: $black-87-opacity,
    600: $black-87-opacity,
    700: $black-87-opacity,
    800: white,
    900: white,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: black,
  )
);

$mat-deep-orange: (
  50: #fbe9e7,
  100: #ffccbc,
  200: #ffab91,
  300: #ff8a65,
  400: #ff7043,
  500: #ff5722,
  600: #f4511e,
  700: #e64a19,
  800: #d84315,
  900: #bf360c,
  A100: #ff9e80,
  A200: #ff6e40,
  A400: #ff3d00,
  A700: #dd2c00,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: white,
    A700: white,
  )
);

$mat-brown: (
  50: #efebe9,
  100: #d7ccc8,
  200: #bcaaa4,
  300: #a1887f,
  400: #8d6e63,
  500: #795548,
  600: #6d4c41,
  700: #5d4037,
  800: #4e342e,
  900: #3e2723,
  A100: #d7ccc8,
  A200: #bcaaa4,
  A400: #8d6e63,
  A700: #5d4037,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: white,
    400: white,
    500: $white-87-opacity,
    600: $white-87-opacity,
    700: $white-87-opacity,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: white,
    A700: $white-87-opacity,
  )
);

$mat-grey: (
  0: #ffffff,
  50: #fafafa,
  100: #f5f5f5,
  200: #eeeeee,
  300: #e0e0e0,
  400: #bdbdbd,
  500: #9e9e9e,
  600: #757575,
  700: #616161,
  800: #424242,
  900: #212121,
  1000: #000000,
  A100: #ffffff,
  A200: #eeeeee,
  A400: #bdbdbd,
  A700: #616161,
  contrast: (
    0: $black-87-opacity,
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: $black-87-opacity,
    500: $black-87-opacity,
    600: $white-87-opacity,
    700: $white-87-opacity,
    800: $white-87-opacity,
    900: $white-87-opacity,
    1000: $white-87-opacity,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: $black-87-opacity,
    A700: $white-87-opacity,
  )
);

$mat-blue-grey: (
  50: #eceff1,
  100: #cfd8dc,
  200: #b0bec5,
  300: #90a4ae,
  400: #78909c,
  500: #607d8b,
  600: #546e7a,
  700: #455a64,
  800: #37474f,
  900: #263238,
  A100: #cfd8dc,
  A200: #b0bec5,
  A400: #78909c,
  A700: #455a64,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: $black-87-opacity,
    400: white,
    500: white,
    600: $white-87-opacity,
    700: $white-87-opacity,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: $black-87-opacity,
    A400: white,
    A700: $white-87-opacity,
  )
);


// Background palette for light themes.
$mat-light-theme-background: (
  status-bar: map_get($mat-grey, 300),
  app-bar:    map_get($mat-grey, 100),
  background: map_get($mat-grey, 50),
  hover:      rgba(black, 0.04), // TODO(kara): check style with Material Design UX
  card:       white,
  dialog:     white,
  disabled-button: $black-12-opacity,
  raised-button: white,
  focused-button: $black-6-opacity,
  selected-button: map_get($mat-grey, 300),
  selected-disabled-button: map_get($mat-grey, 400),
  disabled-button-toggle: map_get($mat-grey, 200),
);

// Background palette for dark themes.
$mat-dark-theme-background: (
  status-bar: black,
  app-bar:    map_get($mat-grey, 900),
  background: #303030,
  hover:      rgba(white, 0.04), // TODO(kara): check style with Material Design UX
  card:       map_get($mat-grey, 800),
  dialog:     map_get($mat-grey, 800),
  disabled-button: $white-12-opacity,
  raised-button: map-get($mat-grey, 800),
  focused-button: $white-6-opacity,
  selected-button: map_get($mat-grey, 900),
  selected-disabled-button: map_get($mat-grey, 800),
  disabled-button-toggle: map_get($mat-grey, 1000),
);

// Foreground palette for light themes.
$mat-light-theme-foreground: (
  base:              black,
  divider:           $black-12-opacity,
  dividers:          $black-12-opacity,
  disabled:          rgba(black, 0.38),
  disabled-button:   rgba(black, 0.38),
  disabled-text:     rgba(black, 0.38),
  hint-text:         rgba(black, 0.38),
  secondary-text:    rgba(black, 0.54),
  icon:              rgba(black, 0.54),
  icons:             rgba(black, 0.54),
  text:              rgba(black, 0.87),
  slider-off:        rgba(black, 0.26),
  slider-off-active: rgba(black, 0.38),
);

// Foreground palette for dark themes.
$mat-dark-theme-foreground: (
  base:              white,
  divider:           $white-12-opacity,
  dividers:          $white-12-opacity,
  disabled:          rgba(white, 0.3),
  disabled-button:   rgba(white, 0.3),
  disabled-text:     rgba(white, 0.3),
  hint-text:         rgba(white, 0.3),
  secondary-text:    rgba(white, 0.7),
  icon:              white,
  icons:             white,
  text:              white,
  slider-off:        rgba(white, 0.3),
  slider-off-active: rgba(white, 0.3),
);



// For a given hue in a palette, return the contrast color from the map of contrast palettes.
// @param $color-map
// @param $hue
@function mat-contrast($palette, $hue) {
  @return map-get(map-get($palette, contrast), $hue);
}


// Creates a map of hues to colors for a theme. This is used to define a theme palette in terms
// of the Material Design hues.
// @param $color-map
// @param $primary
// @param $lighter
@function mat-palette($base-palette, $default: 500, $lighter: 100, $darker: 700) {
  $result: map_merge($base-palette, (
    default: map-get($base-palette, $default),
    lighter: map-get($base-palette, $lighter),
    darker: map-get($base-palette, $darker),

    default-contrast: mat-contrast($base-palette, $default),
    lighter-contrast: mat-contrast($base-palette, $lighter),
    darker-contrast: mat-contrast($base-palette, $darker)
  ));

  // For each hue in the palette, add a "-contrast" color to the map.
  @each $hue, $color in $base-palette {
    $result: map_merge($result, (
      '#{$hue}-contrast': mat-contrast($base-palette, $hue)
    ));
  }

  @return $result;
}


// Gets a color from a theme palette (the output of mat-palette).
// The hue can be one of the standard values (500, A400, etc.), one of the three preconfigured
// hues (default, lighter, darker), or any of the aforementioned prefixed with "-contrast".
//
// @param $color-map The theme palette (output of mat-palette).
// @param $hue The hue from the palette to use. If this is a value between 0 and 1, it will
//     be treated as opacity.
// @param $opacity The alpha channel value for the color.
@function mat-color($palette, $hue: default, $opacity: null) {
  // If hueKey is a number between zero and one, then it actually contains an
  // opacity value, so recall this function with the default hue and that given opacity.
  @if type-of($hue) == number and $hue >= 0 and $hue <= 1 {
    @return mat-color($palette, default, $hue);
  }

  $color: map-get($palette, $hue);
  $opacity: if($opacity == null, opacity($color), $opacity);

  @return rgba($color, $opacity);
}


// Creates a container object for a light theme to be given to individual component theme mixins.
@function mat-light-theme($primary, $accent, $warn: mat-palette($mat-red)) {
  @return (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    is-dark: false,
    foreground: $mat-light-theme-foreground,
    background: $mat-light-theme-background,
  );
}


// Creates a container object for a dark theme to be given to individual component theme mixins.
@function mat-dark-theme($primary, $accent, $warn: mat-palette($mat-red)) {
  @return (
    primary: $primary,
    accent: $accent,
    warn: $warn,
    is-dark: true,
    foreground: $mat-dark-theme-foreground,
    background: $mat-dark-theme-background,
  );
}


$mat-ripple-color-opacity: 0.1;

@mixin mat-ripple() {
  // The host element of an md-ripple directive should always have a position of "absolute" or
  // "relative" so that the ripple divs it creates inside itself are correctly positioned.
  .mat-ripple {
    overflow: hidden;
  }

  .mat-ripple.mat-ripple-unbounded {
    overflow: visible;
  }

  .mat-ripple-element {
    position: absolute;
    border-radius: 50%;
    pointer-events: none;

    transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
    transform: scale(0);
  }
}

/* Theme for the ripple elements.*/
@mixin mat-ripple-theme($theme) {
  $foreground: map_get($theme, foreground);
  $foreground-base: map_get($foreground, base);

  .mat-ripple-element {
    background-color: rgba($foreground-base, $mat-ripple-color-opacity);
  }
}


// A mixin, which generates temporary ink ripple on a given component.
// To be removed once the real ripple is applied to all elements.
// When $bindToParent is set to true, it will check for the focused class on the same selector as you included
// that mixin.
// It is also possible to specify the color palette of the temporary ripple. By default it uses the
// accent palette for its background.
@mixin mat-temporary-ink-ripple($component, $bindToParent: false) {
  // TODO(mtlin): Replace when ink ripple component is implemented.
  // A placeholder ink ripple, shown when keyboard focused.
  .mat-ink-ripple {
    border-radius: 50%;
    opacity: 0;
    height: 48px;
    left: 50%;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity ease 280ms, background-color ease 280ms;
    width: 48px;
  }

  // Fade in when radio focused.
  #{if($bindToParent, '&', '')}.mat-#{$component}-focused .mat-ink-ripple {
    opacity: 1;
  }

  // TODO(mtlin): This corresponds to disabled focus state, but it's unclear how to enter into
  // this state.
  #{if($bindToParent, '&', '')}.mat-#{$component}-disabled .mat-ink-ripple {
    background-color: #000;
  }
}



// This mixin will ensure that lines that overflow the container will hide the overflow and
// truncate neatly with an ellipsis.
@mixin mat-truncate-line() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Mixin to provide all mat-line styles, changing secondary font size based on whether the list
// is in dense mode.
@mixin mat-line-base($secondary-font-size) {
  .mat-line {
    @include mat-truncate-line();
    display: block;
    box-sizing: border-box;

    // all lines but the top line should have smaller text
    &:nth-child(n+2) {
      font-size: $secondary-font-size;
    }
  }
}

// This mixin normalizes default element styles, e.g. font weight for heading text.
@mixin mat-normalize-text() {
  & > * {
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-size: inherit;
  }
}

// This mixin provides base styles for the wrapper around mat-line elements in a list.
@mixin mat-line-wrapper-base() {
  @include mat-normalize-text();

  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;

  // Must remove wrapper when lines are empty or it takes up horizontal
  // space and pushes other elements to the right.
  &:empty {
    display: none;
  }
}


/** The mixins below are shared between md-menu and md-select */

// menu width must be a multiple of 56px
$mat-menu-overlay-min-width: 112px !default;   // 56 * 2
$mat-menu-overlay-max-width: 280px !default;   // 56 * 5

$mat-menu-item-height: 48px !default;
$mat-menu-side-padding: 16px !default;
$mat-menu-icon-margin: 16px !default;


@mixin mat-menu-base() {
  @include mat-elevation(8);
  min-width: $mat-menu-overlay-min-width;
  max-width: $mat-menu-overlay-max-width;

  overflow: auto;
  -webkit-overflow-scrolling: touch;   // for momentum scroll on mobile
}

@mixin mat-menu-item-base() {
  @include mat-truncate-line();

  // Needs to be a block for the ellipsis to work.
  display: block;
  line-height: $mat-menu-item-height;
  height: $mat-menu-item-height;
  padding: 0 $mat-menu-side-padding;

  text-align: left;
  text-decoration: none;   // necessary to reset anchor tags

  &[disabled] {
    cursor: default;
  }

  [dir='rtl'] & {
    text-align: right;
  }

  .mat-icon {
    margin-right: $mat-menu-icon-margin;

    [dir='rtl'] & {
      margin-left: $mat-menu-icon-margin;
      margin-right: 0;
    }
  }
}

/**
 * This mixin adds the correct panel transform styles based
 * on the direction that the menu panel opens.
 */
@mixin mat-menu-positions() {
  &.mat-menu-after.mat-menu-below {
    transform-origin: left top;
  }

  &.mat-menu-after.mat-menu-above {
    transform-origin: left bottom;
  }

  &.mat-menu-before.mat-menu-below {
    transform-origin: right top;
  }

  &.mat-menu-before.mat-menu-above {
    transform-origin: right bottom;
  }

  [dir='rtl'] & {
    &.mat-menu-after.mat-menu-below {
      transform-origin: right top;
    }

    &.mat-menu-after.mat-menu-above {
      transform-origin: right bottom;
    }

    &.mat-menu-before.mat-menu-below {
      transform-origin: left top;
    }

    &.mat-menu-before.mat-menu-above {
      transform-origin: left bottom;
    }
  }
}

/* stylelint-disable material/no-prefixes */
@mixin user-select($value) {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin input-placeholder {
  &::placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &::-webkit-input-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

@mixin cursor-grab {
  cursor: -webkit-grab;
  cursor: grab;
}

@mixin cursor-grabbing {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
/* stylelint-enable */



/**
 * This mixin contains shared option styles between the select and
 * autocomplete components.
 */
@mixin mat-option() {
  .mat-option {
    @include mat-menu-item-base();
    position: relative;
    cursor: pointer;
    outline: none;

    &[aria-disabled='true'] {
      @include user-select(none);
      cursor: default;
    }

    .mat-optgroup &:not(.mat-option-multiple) {
      padding-left: $mat-menu-side-padding * 2;

      [dir='rtl'] & {
        padding-left: $mat-menu-side-padding;
        padding-right: $mat-menu-side-padding * 2;
      }
    }
  }

  .mat-option-ripple {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    // Disable pointer events for the ripple container because the container will overlay the
    // user content and we don't want to disable mouse events on the user content.
    // Pointer events can be safely disabled because the ripple trigger element is the host element.
    pointer-events: none;

    // Prevents the ripple from completely covering the option in high contrast mode.
    @include cdk-high-contrast {
      opacity: 0.5;
    }
  }

  .mat-option-pseudo-checkbox {
    $margin: $mat-menu-side-padding / 2;
    margin-right: $margin;

    [dir='rtl'] & {
      margin-left: $margin;
      margin-right: 0;
    }
  }
}




// Utility for fetching a nested value from a typography config.
@function _mat-get-type-value($config, $level, $name) {
  @return map-get(map-get($config, $level), $name);
}

// Gets the font size for a level inside a typography config.
@function mat-font-size($config, $level) {
  @return _mat-get-type-value($config, $level, font-size);
}

// Gets the line height for a level inside a typography config.
@function mat-line-height($config, $level) {
  @return _mat-get-type-value($config, $level, line-height);
}

// Gets the font weight for a level inside a typography config.
@function mat-font-weight($config, $level) {
  @return _mat-get-type-value($config, $level, font-weight);
}

// Gets the font-family from a typography config and removes the quotes around it.
@function mat-font-family($config) {
  @return unquote(map-get($config, font-family));
}

// Converts a typography level into CSS styles.
@mixin mat-typography-level-to-styles($config, $level) {
  $font-size: mat-font-size($config, $level);
  $font-weight: mat-font-weight($config, $level);
  $line-height: mat-line-height($config, $level);
  $font-family: mat-font-family($config);

  // Use the shorthand `font` to represent a typography level, because it's the shortest. Notes that
  // we need to use interpolation for `font-size/line-height` in order to prevent SASS from dividing
  // the two values.
  font: $font-weight #{$font-size}/#{$line-height} $font-family;
}


@mixin mat-option-theme($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .mat-option {
    color: mat-color($foreground, text);

    &:hover:not(.mat-option-disabled), &:focus:not(.mat-option-disabled) {
      background: mat-color($background, hover);
    }

    &.mat-selected.mat-primary, .mat-primary &.mat-selected {
      color: mat-color($primary);
    }

    &.mat-selected.mat-accent, .mat-accent &.mat-selected {
      color: mat-color($accent);
    }

    &.mat-selected.mat-warn, .mat-warn &.mat-selected {
      color: mat-color($warn);
    }

    // In multiple mode there is a checkbox to show that the option is selected.
    &.mat-selected:not(.mat-option-multiple) {
      background: mat-color($background, hover);
    }

    &.mat-active {
      background: mat-color($background, hover);
      color: mat-color($foreground, text);
    }

    &.mat-option-disabled {
      color: mat-color($foreground, hint-text);
    }
  }
}

@mixin mat-option-typography($config) {
  .mat-option {
    font: {
      family: mat-font-family($config);
      size: mat-font-size($config, subheading-2);
    }
  }
}




@mixin mat-optgroup() {
  .mat-optgroup-label {
    @include mat-menu-item-base();
    @include user-select(none);
    cursor: default;

    // TODO(crisbeto): should use the typography functions once #4375 is in.
    font-weight: bold;
    font-size: 14px;
  }
}




@mixin mat-optgroup-theme($theme) {
  $foreground: map-get($theme, foreground);

  .mat-optgroup-label {
    color: mat-color($foreground, secondary-text);
  }

  .mat-optgroup-disabled .mat-optgroup-label {
    color: mat-color($foreground, hint-text);
  }
}



@mixin _mat-pseudo-checkbox-inner-content-theme($theme, $pallete-name) {
  $pallete: map-get($theme, $pallete-name);
  $color: mat-color($pallete);

  .mat-pseudo-checkbox-checked.mat-#{$pallete-name},
  .mat-pseudo-checkbox-indeterminate.mat-#{$pallete-name},
  .mat-#{$pallete-name} .mat-pseudo-checkbox-checked,
  .mat-#{$pallete-name} .mat-pseudo-checkbox-indeterminate {
    background: $color;
  }
}

@mixin mat-pseudo-checkbox-theme($theme) {
  $is-dark-theme: map-get($theme, is-dark);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);

  // NOTE(traviskaufman): While the spec calls for translucent blacks/whites for disabled colors,
  // this does not work well with elements layered on top of one another. To get around this we
  // blend the colors together based on the base color and the theme background.
  $white-30pct-opacity-on-dark: #686868;
  $black-26pct-opacity-on-light: #b0b0b0;
  $disabled-color: if($is-dark-theme, $white-30pct-opacity-on-dark, $black-26pct-opacity-on-light);
  $colored-box-selector: '.mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate';

  .mat-pseudo-checkbox {
    color: mat-color(map-get($theme, foreground), secondary-text);

    &::after {
      color: mat-color($background, background);
    }
  }

  @include _mat-pseudo-checkbox-inner-content-theme($theme, primary);
  @include _mat-pseudo-checkbox-inner-content-theme($theme, accent);
  @include _mat-pseudo-checkbox-inner-content-theme($theme, warn);

  .mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate {
    &.mat-pseudo-checkbox-disabled {
      background: $disabled-color;
    }
  }
}



// Represents a typography level from the Material design spec.
@function mat-typography-level($font-size, $line-height: $font-size, $font-weight: 400) {
  @return (
    font-size: $font-size,
    line-height: $line-height,
    font-weight: $font-weight
  );
}

// Represents a collection of typography levels.
// Defaults come from https://material.io/guidelines/style/typography.html
@function mat-typography-config(
  $font-family:   'Arial',
  $display-4:     mat-typography-level(112px, 112px, 300),
  $display-3:     mat-typography-level(56px, 56px, 400),
  $display-2:     mat-typography-level(45px, 48px, 400),
  $display-1:     mat-typography-level(34px, 40px, 400),
  $headline:      mat-typography-level(24px, 32px, 400),
  $title:         mat-typography-level(20px, 32px, 500),
  $subheading-2:  mat-typography-level(16px, 28px, 400),
  $subheading-1:  mat-typography-level(15px, 24px, 400),
  $body-2:        mat-typography-level(14px, 24px, 500),
  $body-1:        mat-typography-level(14px, 20px, 400),
  $caption:       mat-typography-level(12px, 20px, 400),
  $button:        mat-typography-level(14px, 14px, 500),
  // Line-height must be unit-less fraction of the font-size.
  $input:         mat-typography-level(inherit, 1.125, 400)
) {
  @return (
    font-family:    $font-family,
    display-4:      $display-4,
    display-3:      $display-3,
    display-2:      $display-2,
    display-1:      $display-1,
    headline:       $headline,
    title:          $title,
    subheading-2:   $subheading-2,
    subheading-1:   $subheading-1,
    body-2:         $body-2,
    body-1:         $body-1,
    caption:        $caption,
    button:         $button,
    input:          $input,
  );
}

// Adds the base typography styles, based on a config.
@mixin mat-base-typography($config, $selector: '.mat-typography') {
  .mat-h1, .mat-headline, #{$selector} h1 {
    @include mat-typography-level-to-styles($config, headline);
    margin: 0 0 16px;
  }

  .mat-h2, .mat-title, #{$selector} h2 {
    @include mat-typography-level-to-styles($config, title);
    margin: 0 0 16px;
  }

  .mat-h3, .mat-subheading-2, #{$selector} h3 {
    @include mat-typography-level-to-styles($config, subheading-2);
    margin: 0 0 16px;
  }

  .mat-h4, .mat-subheading-1, #{$selector} h4 {
    @include mat-typography-level-to-styles($config, subheading-1);
    margin: 0 0 16px;
  }

  // Note: the spec doesn't have anything that would correspond to h5 and h6, but we add these for
  // consistency. The font sizes come from the Chrome user agent styles which have h5 at 0.83em
  // and h6 at 0.67em.
  .mat-h5, #{$selector} h5 {
    font-size: mat-font-size($config, body-1) * 0.83;
    font-weight: mat-font-weight($config, body-1);
    font-family: mat-font-family($config);
    line-height: mat-line-height($config, body-1);
    margin: 0 0 12px;
  }

  .mat-h6, #{$selector} h6 {
    font-size: mat-font-size($config, body-1) * 0.67;
    font-weight: mat-font-weight($config, body-1);
    font-family: mat-font-family($config);
    line-height: mat-line-height($config, body-1);
    margin: 0 0 12px;
  }

  .mat-body-strong, .mat-body-2 {
    @include mat-typography-level-to-styles($config, body-2);
  }

  .mat-body, .mat-body-1, #{$selector} {
    @include mat-typography-level-to-styles($config, body-1);

    p {
      margin: 0 0 12px;
    }
  }

  .mat-small, .mat-caption {
    @include mat-typography-level-to-styles($config, caption);
  }

  // Note: The spec doesn't mention letter spacing. The value comes from
  // eyeballing it until it looked exactly like the spec examples.
  .mat-display-4, #{$selector} .mat-display-4 {
    @include mat-typography-level-to-styles($config, display-4);
    margin: 0 0 56px;
    letter-spacing: -0.05em;
  }

  .mat-display-3, #{$selector} .mat-display-3 {
    @include mat-typography-level-to-styles($config, display-3);
    margin: 0 0 64px;
    letter-spacing: -0.02em;
  }

  .mat-display-2, #{$selector} .mat-display-2 {
    @include mat-typography-level-to-styles($config, display-2);
    margin: 0 0 64px;
    letter-spacing: -0.005em;
  }

  .mat-display-1, #{$selector} .mat-display-1 {
    @include mat-typography-level-to-styles($config, display-1);
    margin: 0 0 64px;
  }
}



@mixin mat-autocomplete-theme($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  .mat-autocomplete-panel {
    background: mat-color($background, card);
    color: mat-color($foreground, text);

    .mat-option {
      // Selected options in autocompletes should not be gray, but we
      // only want to override the background for selected options if
      // they are *not* in hover or focus state. This change has to be
      // made here because base option styles are shared between the
      // autocomplete and the select.
      &.mat-selected:not(.mat-active):not(:hover) {
        background: mat-color($background, card);
        color: mat-color($foreground, text);
      }
    }
  }

}

@mixin mat-autocomplete-typography($config) { }




// Applies a focus style to an md-button element for each of the supported palettes.
@mixin _mat-button-focus-color($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  &.mat-primary .mat-button-focus-overlay {
    background-color: mat-color($primary, 0.12);
  }

  &.mat-accent .mat-button-focus-overlay {
    background-color: mat-color($accent, 0.12);
  }

  &.mat-warn .mat-button-focus-overlay {
    background-color: mat-color($warn, 0.12);
  }

  &[disabled] .mat-button-focus-overlay {
    background-color: transparent;
  }
}

@mixin _mat-button-ripple-color($theme, $hue, $opacity: 0.2) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  &.mat-primary .mat-ripple-element {
    background-color: mat-color($primary, $hue, $opacity);
  }

  &.mat-accent .mat-ripple-element {
    background-color: mat-color($accent, $hue, $opacity);
  }

  &.mat-warn .mat-ripple-element {
    background-color: mat-color($warn, $hue, $opacity);
  }
}

// Applies a property to an md-button element for each of the supported palettes.
@mixin _mat-button-theme-color($theme, $property, $color: 'default') {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  &.mat-primary {
    #{$property}: mat-color($primary, $color);
  }
  &.mat-accent {
    #{$property}: mat-color($accent, $color);
  }
  &.mat-warn {
    #{$property}: mat-color($warn, $color);
  }

  &.mat-primary, &.mat-accent, &.mat-warn, &[disabled] {
    &[disabled] {
      $palette: if($property == 'color', $foreground, $background);
      #{$property}: mat-color($palette, disabled-button);
    }
  }
}

@mixin mat-button-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-button, .mat-icon-button {
    background: transparent;

    @include _mat-button-focus-color($theme);
    @include _mat-button-theme-color($theme, 'color');
  }

  .mat-raised-button, .mat-fab, .mat-mini-fab {
    // Default properties when not using any [color] value.
    color: mat-color($foreground, text);
    background-color: mat-color($background, raised-button);

    @include _mat-button-theme-color($theme, 'color', default-contrast);
    @include _mat-button-theme-color($theme, 'background-color');

    // Add ripple effect with contrast color to buttons that don't have a focus overlay.
    @include _mat-button-ripple-color($theme, default-contrast);
  }

  // Add ripple effect with default color to flat buttons, which also have a focus overlay.
  .mat-button {
    @include _mat-button-ripple-color($theme, default, 0.1);
  }

  // Add ripple effect with default color to the icon button. Ripple color needs to be stronger
  // since the icon button doesn't have a focus overlay.
  .mat-icon-button {
    @include _mat-button-ripple-color($theme, default);
  }
}

@mixin mat-button-typography($config) {
  .mat-button, .mat-raised-button, .mat-icon-button {
    font: {
      family: mat-font-family($config);
      size: mat-font-size($config, button);
      weight: mat-font-weight($config, button);
    }
  }
}





// Applies a focus style to an md-button-toggle element for each of the supported palettes.
@mixin _mat-button-toggle-focus-color($theme) {
  $background: map-get($theme, background);

  .mat-button-toggle-focus-overlay {
    background-color: mat-color($background, focused-button);
  }
}

@mixin mat-button-toggle-theme($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  .mat-button-toggle {
    color: mat-color($foreground, hint-text);

    &.cdk-focused {
      @include _mat-button-toggle-focus-color($theme);
    }
  }

  .mat-button-toggle-checked {
    background-color: mat-color($background, selected-button);
    color: mat-color($foreground, base);
  }

  .mat-button-toggle-disabled {
    background-color: mat-color($background, disabled-button-toggle);
    color: mat-color($foreground, disabled-button);

    &.mat-button-toggle-checked {
      background-color: mat-color($background, selected-disabled-button);
    }
  }
}

@mixin mat-button-toggle-typography($config) {
  .mat-button-toggle {
    font-family: mat-font-family($config);
  }
}






@mixin mat-card-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-card {
    background: mat-color($background, card);
    color: mat-color($foreground, text);
  }

  .mat-card-subtitle {
    color: mat-color($foreground, secondary-text);
  }
}

@mixin mat-card-typography($config) {
  .mat-card {
    font-family: mat-font-family($config);
  }

  .mat-card-title {
    font: {
      size: mat-font-size($config, headline);
      weight: mat-font-weight($config, headline);
    }
  }

  .mat-card-subtitle,
  .mat-card-content,
  .mat-card-header .mat-card-title {
    font-size: mat-font-size($config, body-1);
  }
}





@mixin mat-checkbox-theme($theme) {
  $is-dark-theme: map-get($theme, is-dark);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);


  // The color of the checkbox's checkmark / mixedmark.
  $checkbox-mark-color: mat-color($background, background);

  // NOTE(traviskaufman): While the spec calls for translucent blacks/whites for disabled colors,
  // this does not work well with elements layered on top of one another. To get around this we
  // blend the colors together based on the base color and the theme background.
  $white-30pct-opacity-on-dark: #686868;
  $black-26pct-opacity-on-light: #b0b0b0;
  $disabled-color: if($is-dark-theme, $white-30pct-opacity-on-dark, $black-26pct-opacity-on-light);

  .mat-checkbox-frame {
    border-color: mat-color(map-get($theme, foreground), secondary-text);
  }

  .mat-checkbox-checkmark {
    fill: $checkbox-mark-color;
  }

  .mat-checkbox-checkmark-path {
    // !important is needed here because a stroke must be set as an attribute on the SVG in order
    // for line animation to work properly.
    stroke: $checkbox-mark-color !important;
  }

  .mat-checkbox-mixedmark {
    background-color: $checkbox-mark-color;
  }

  .mat-checkbox-indeterminate, .mat-checkbox-checked {
    &.mat-primary .mat-checkbox-background {
      background-color: mat-color($primary);
    }

    &.mat-accent .mat-checkbox-background {
      background-color: mat-color($accent);
    }

    &.mat-warn .mat-checkbox-background {
      background-color: mat-color($warn);
    }
  }

  .mat-checkbox-disabled {
    &.mat-checkbox-checked, &.mat-checkbox-indeterminate {
      .mat-checkbox-background {
        background-color: $disabled-color;
      }
    }

    &:not(.mat-checkbox-checked) {
      .mat-checkbox-frame {
        border-color: $disabled-color;
      }
    }

    .mat-checkbox-label {
      color: $disabled-color;
    }
  }

  .mat-checkbox:not(.mat-checkbox-disabled) {
    &.mat-primary .mat-checkbox-ripple .mat-ripple-element {
      background-color: mat-color($primary, 0.26);
    }

    &.mat-accent .mat-checkbox-ripple .mat-ripple-element {
      background-color: mat-color($accent, 0.26);
    }

    &.mat-warn .mat-checkbox-ripple .mat-ripple-element {
      background-color: mat-color($warn, 0.26);
    }
  }
}

@mixin mat-checkbox-typography($config) {
  .mat-checkbox {
    font-family: mat-font-family($config);
  }

  // TODO(kara): Remove this style when fixing vertical baseline
  .mat-checkbox-layout .mat-checkbox-label {
    line-height: mat-line-height($config, body-2);
  }
}





// TODO(crisbeto): these values don't correspond to any of the typography breakpoints.
$mat-chip-font-size: 13px;
$mat-chip-line-height: 16px;


@mixin mat-chips-theme($theme) {
  $is-dark-theme: map-get($theme, is-dark);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  // Use spec-recommended color for regular foreground, and utilise contrast color for a grey very
  // close to the selected spec since no guidance is provided and to ensure palette consistency.
  $light-foreground: rgba(0, 0, 0, 0.87);
  $light-selected-foreground: mat-contrast($mat-grey, 600);

  // The spec only provides guidance for light-themed chips. When inside of a dark theme, fall back
  // to standard background and foreground colors.
  $unselected-background: if($is-dark-theme, mat-color($background, card), #e0e0e0);
  $unselected-foreground: if($is-dark-theme, mat-color($foreground, text), $light-foreground);

  $selected-background: if($is-dark-theme, mat-color($background, app-bar), #808080);
  $selected-foreground: if($is-dark-theme, mat-color($foreground, text), $light-selected-foreground);

  .mat-chip:not(.mat-basic-chip) {
    background-color: $unselected-background;
    color: $unselected-foreground;
  }

  .mat-chip.mat-chip-selected:not(.mat-basic-chip) {
    background-color: $selected-background;
    color: $selected-foreground;

    &.mat-primary {
      background-color: mat-color($primary);
      color: mat-color($primary, default-contrast);
    }

    &.mat-accent {
      background-color: mat-color($accent);
      color: mat-color($accent, default-contrast);
    }

    &.mat-warn {
      background-color: mat-color($warn);
      color: mat-color($warn, default-contrast);
    }
  }
}

@mixin mat-chips-typography($config) {
  .mat-chip:not(.mat-basic-chip) {
    font-size: $mat-chip-font-size;
    line-height: $mat-chip-line-height;
  }
}






$mat-calendar-body-font-size: 13px !default;
$mat-calendar-weekday-table-font-size: 11px !default;


@mixin mat-datepicker-theme($theme) {
  $primary: map-get($theme, primary);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  $mat-datepicker-selected-today-box-shadow-width: 1px;
  $mat-datepicker-selected-fade-amount: 0.6;
  $mat-datepicker-today-fade-amount: 0.2;

  .mat-datepicker-content {
    background-color: mat-color($background, card);
  }

  .mat-calendar-arrow {
    border-top-color: mat-color($foreground, icon);
  }

  .mat-calendar-next-button,
  .mat-calendar-previous-button {
    color: mat-color($foreground, icon);
  }

  .mat-calendar-table-header {
    color: mat-color($foreground, hint-text);
  }

  .mat-calendar-table-header-divider::after {
    background: mat-color($foreground, divider);
  }

  .mat-calendar-body-label {
    color: mat-color($foreground, secondary-text);
  }

  .mat-calendar-body-cell-content {
    color: mat-color($foreground, text);
    border-color: transparent;

    .mat-calendar-body-disabled > &:not(.mat-calendar-body-selected) {
      color: mat-color($foreground, disabled-text);
    }
  }

  :not(.mat-calendar-body-disabled):hover,
  .cdk-keyboard-focused .mat-calendar-body-active {
    & > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
      background-color: mat-color($background, hover);
    }
  }

  .mat-calendar-body-selected {
    background-color: mat-color($primary);
    color: mat-color($primary, default-contrast);
  }

  .mat-calendar-body-disabled > .mat-calendar-body-selected {
    background-color: fade-out(mat-color($primary), $mat-datepicker-selected-fade-amount);
  }

  .mat-calendar-body-today {
    &:not(.mat-calendar-body-selected) {
      // Note: though it's not text, the border is a hint about the fact that this is today's date,
      // so we use the hint color.
      border-color: mat-color($foreground, hint-text);
    }

    &.mat-calendar-body-selected {
      box-shadow: inset 0 0 0 $mat-datepicker-selected-today-box-shadow-width
                  mat-color($primary, default-contrast);
    }
  }

  .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected) {
    border-color: fade-out(mat-color($foreground, hint-text), $mat-datepicker-today-fade-amount);
  }
}

@mixin mat-datepicker-typography($config) {
  .mat-calendar {
    font-family: mat-font-family($config);
  }

  .mat-calendar-body {
    font-size: $mat-calendar-body-font-size;
  }

  .mat-calendar-body-label,
  .mat-calendar-period-button {
    font: {
      size: mat-font-size($config, button);
      weight: mat-font-weight($config, button);
    }
  }

  .mat-calendar-table-header th {
    font: {
      size: $mat-calendar-weekday-table-font-size;
      weight: mat-font-weight($config, body-1);
    }
  }
}






@mixin mat-dialog-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-dialog-container {
    background: mat-color($background, dialog);
    color: mat-color($foreground, text);
  }
}

@mixin mat-dialog-typography($config) {
  .mat-dialog-title {
    @include mat-typography-level-to-styles($config, title);
  }
}







// Include this empty mixin for consistency with the other components.
@mixin mat-grid-list-theme($theme) { }

@mixin mat-grid-list-typography($config) {
  .mat-grid-tile-header,
  .mat-grid-tile-footer {
    @include mat-line-base(mat-font-size($config, caption));
    font-size: mat-font-size($config, body-1);
  }
}




// Include this empty mixin for consistency with the other components.
@mixin mat-icon-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-icon {
    &.mat-primary {
      color: mat-color($primary);
    }

    &.mat-accent {
      color: mat-color($accent);
    }

    &.mat-warn {
      color: mat-color($warn);
    }
  }
}

@mixin mat-icon-typography($config) { }






@mixin mat-input-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  // Placeholder colors. Required is used for the `*` star shown in the placeholder.
  $input-placeholder-color: mat-color($foreground, hint-text);
  $input-floating-placeholder-color: mat-color($primary);
  $input-required-placeholder-color: mat-color($accent);

  // Underline colors.
  $input-underline-color: mat-color($foreground, divider);
  $input-underline-color-accent: mat-color($accent);
  $input-underline-color-warn: mat-color($warn);
  $input-underline-focused-color: mat-color($primary);

  .mat-input-placeholder {
    color: $input-placeholder-color;
  }

  // :focus is applied to the input, but we apply mat-focused to the other elements
  // that need to listen to it.
  .mat-focused .mat-input-placeholder {
    color: $input-floating-placeholder-color;

    &.mat-accent {
      color: $input-underline-color-accent;
    }

    &.mat-warn {
      color: $input-underline-color-warn;
    }
  }

  .mat-input-element:disabled {
    color: mat-color($foreground, disabled-text);
  }

  // See mat-input-placeholder-floating mixin in input-container.scss
  input.mat-input-element:-webkit-autofill + .mat-input-placeholder,
  .mat-focused .mat-input-placeholder.mat-float {
    .mat-placeholder-required {
      color: $input-required-placeholder-color;
    }
  }

  .mat-input-underline {
    background-color: $input-underline-color;
  }

  .mat-input-ripple {
    background-color: $input-underline-focused-color;

    &.mat-accent {
      background-color: $input-underline-color-accent;
    }

    &.mat-warn {
      background-color: $input-underline-color-warn;
    }
  }

  // Styling for the error state of the input container. Note that while the same can be
  // achieved with the ng-* classes, we use this approach in order to ensure that the same
  // logic is used to style the error state and to show the error messages.
  .mat-input-invalid {
    .mat-input-placeholder {
      color: $input-underline-color-warn;

      &.mat-accent,
      &.mat-float .mat-placeholder-required {
        color: $input-underline-color-warn;
      }
    }

    .mat-input-ripple {
      background-color: $input-underline-color-warn;
    }
  }

  .mat-input-error {
    color: $input-underline-color-warn;
  }
}

@mixin mat-input-typography($config) {
  // The unit-less line-height from the font config.
  $mat-input-line-height: mat-line-height($config, input);

  // The amount to scale the font for the floating label and subscript.
  $mat-input-subscript-font-scale: 0.75;
  // The amount to scale the font for the prefix and suffix icons.
  $mat-input-prefix-suffix-icon-font-scale: 1.5;

  // The amount of space between the top of the line and the top of the actual text
  // (as a fraction of the font-size).
  $mat-input-line-spacing: ($mat-input-line-height - 1) / 2;
  // The padding on the infix. Mocks show half of the text size, but seem to measure from the edge
  // of the text itself, not the edge of the line; therefore we subtract off the line spacing.
  $mat-input-infix-padding: 0.5em - $mat-input-line-spacing;
  // The margin applied to the input-infix to reserve space for the floating label.
  $mat-input-infix-margin-top: 1em * $mat-input-line-height * $mat-input-subscript-font-scale;
  // Font size to use for the label and subscript text.
  $mat-input-subscript-font-size: $mat-input-subscript-font-scale * 100%;
  // Font size to use for the for the prefix and suffix icons.
  $mat-input-prefix-suffix-icon-font-size: $mat-input-prefix-suffix-icon-font-scale * 100%;
  // The space between the bottom of the input table and the subscript container. Mocks show half of
  // the text size, but this margin is applied to an element with the subscript text font size, so
  // we need to divide by the scale factor to make it half of the original text size. We again need
  // to subtract off the line spacing since the mocks measure to the edge of the text, not the  edge
  // of the line.
  $mat-input-subscript-margin-top:
      0.5em / $mat-input-subscript-font-scale - $mat-input-line-spacing;
  // The padding applied to the input-wrapper to reserve space for the subscript, since it's
  // absolutely positioned. This is a combination of the subscript's margin and line-height, but we
  // need to multiply by the subscript font scale factor since the wrapper has a larger font size.
  $mat-input-wrapper-padding-bottom:
      ($mat-input-subscript-margin-top + $mat-input-line-height) * $mat-input-subscript-font-scale;

  // Applies a floating placeholder above the input itself.
  // @mixin mat-input-placeholder-floating {
  //   transform: translateY(-$mat-input-infix-margin-top - $mat-input-infix-padding)
  //              scale($mat-input-subscript-font-scale)
  //              // We use perspecitve to fix the text blurriness as described here:
  //              // http://www.useragentman.com/blog/2014/05/04/fixing-typography-inside-of-2-d-css-transforms/
  //              // This results in a small jitter after the label floats on Firefox, which the
  //              // translateZ fixes.
  //              perspective(100px) translateZ(0.001px);
  //   // The tricks above used to smooth out the animation on chrome and firefox actually make things
  //   // worse on IE, so we don't include them in the IE version.
  //   -ms-transform: translateY(-$mat-input-infix-margin-top - $mat-input-infix-padding)
  //                  scale($mat-input-subscript-font-scale);

  //   width: 100% / $mat-input-subscript-font-scale;
  // }

  .mat-input-container {
    @include mat-typography-level-to-styles($config, input);
  }

  .mat-input-wrapper {
    padding-bottom: $mat-input-wrapper-padding-bottom;
  }

  .mat-input-prefix,
  .mat-input-suffix {
    // Allow icons in a prefix or suffix to adapt to the correct size.
    .mat-icon,
    .mat-datepicker-toggle {
      font-size: $mat-input-prefix-suffix-icon-font-size;
    }

    // Allow icon buttons in a prefix or suffix to adapt to the correct size.
    .mat-icon-button {
      height: $mat-input-prefix-suffix-icon-font-scale * 1em;
      width: $mat-input-prefix-suffix-icon-font-scale * 1em;

      .mat-icon {
        line-height: $mat-input-prefix-suffix-icon-font-scale;
      }
    }
  }

  .mat-input-infix {
    padding: $mat-input-infix-padding 0;
    // Throws off the baseline if we do it as a real margin, so we do it as a border instead.
    border-top: $mat-input-infix-margin-top solid transparent;
  }

  // .mat-input-element {
  //   &:-webkit-autofill + .mat-input-placeholder-wrapper .mat-float {
  //     @include mat-input-placeholder-floating;
  //   }
  // }

  .mat-input-placeholder-wrapper {
    top: -$mat-input-infix-margin-top;
    padding-top: $mat-input-infix-margin-top;
  }

  // .mat-input-placeholder {
  //   top: $mat-input-infix-margin-top + $mat-input-infix-padding;

  //   // Show the placeholder above the input when it's not empty, or focused.
  //   &.mat-float:not(.mat-empty), .mat-focused &.mat-float {
  //     @include mat-input-placeholder-floating;
  //   }
  // }

  .mat-input-underline {
    // We want the underline to start at the end of the content box, not the padding box,
    // so we move it up by the padding amount;
    bottom: $mat-input-wrapper-padding-bottom;
  }

  .mat-input-subscript-wrapper {
    font-size: $mat-input-subscript-font-size;
    margin-top: $mat-input-subscript-margin-top;

    // We want the subscript to start at the end of the content box, not the padding box,
    // so we move it up by the padding amount (adjusted for the smaller font size);
    top: calc(100% - #{$mat-input-wrapper-padding-bottom / $mat-input-subscript-font-scale});
  }
}







@mixin mat-list-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-list, .mat-nav-list {
    .mat-list-item {
      color: mat-color($foreground, text);
    }

    .mat-subheader {
      color: mat-color($foreground, secondary-text);
    }
  }

  .mat-divider {
    border-top-color: mat-color($foreground, divider);
  }

  .mat-nav-list .mat-list-item {
    outline: none;

    &:hover, &.mat-list-item-focus {
      background: mat-color($background, 'hover');
    }
  }
}

@mixin mat-list-typography($config) {
  $font-family: mat-font-family($config);

  .mat-list-item {
    font-family: $font-family;
  }

  // Default list
  .mat-list, .mat-nav-list {
    .mat-list-item {
      font-size: mat-font-size($config, subheading-2);
      @include mat-line-base(mat-font-size($config, body-1));
    }

    .mat-subheader {
      @include mat-typography-level-to-styles($config, body-2);
    }
  }

  // Dense list
  .mat-list[dense], .mat-nav-list[dense] {
    .mat-list-item {
      font-size: mat-font-size($config, caption);
      @include mat-line-base(mat-font-size($config, caption));
    }

    .mat-subheader {
      font: mat-font-weight($config, body-2) mat-font-size($config, caption) $font-family;
    }
  }
}






@mixin mat-menu-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-menu-content {
    background: mat-color($background, 'card');
  }

  .mat-menu-item {
    background: transparent;
    color: mat-color($foreground, 'text');

    &[disabled] {
      color: mat-color($foreground, 'disabled');
    }

    .mat-icon {
      color: mat-color($foreground, 'icon');
      vertical-align: middle;
    }

    &:hover:not([disabled]), &:focus:not([disabled]) {
      background: mat-color($background, 'hover');
    }
  }
}

@mixin mat-menu-typography($config) {
  .mat-menu-item {
    font: {
      family: mat-font-family($config);
      size: mat-font-size($config, subheading-2);
    }
  }
}





@mixin mat-progress-bar-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .mat-progress-bar-background {
    background-image: #{_mat-progress-bar-buffer($primary, lighter)};
  }

  .mat-progress-bar-buffer {
    background-color: mat-color($primary, lighter);
  }

  .mat-progress-bar-fill::after {
    background-color: mat-color($primary);
  }

  .mat-progress-bar.mat-accent {
    .mat-progress-bar-background {
      background-image: #{_mat-progress-bar-buffer($accent, lighter)};
    }

    .mat-progress-bar-buffer {
      background-color: mat-color($accent, lighter);
    }

    .mat-progress-bar-fill::after {
      background-color: mat-color($accent);
    }
  }

  .mat-progress-bar.mat-warn {
    .mat-progress-bar-background {
      background-image: #{_mat-progress-bar-buffer($warn, lighter)};
    }

    .mat-progress-bar-buffer {
      background-color: mat-color($warn, lighter);
    }

    .mat-progress-bar-fill::after {
      background-color: mat-color($warn);
    }
  }
}

@mixin mat-progress-bar-typography($config) { }

// TODO(josephperrott): Find better way to inline svgs.
// In buffer mode a repeated SVG object is used as a background.
// Each of the following defines the SVG object for each of the class defined colors.
//
// The string is a URL encoded version of:
//
// <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
//      version="1.1" x="0px" y="0px" enable-background="new 0 0 5 2"
//      xml:space="preserve" viewBox="0 0 5 2" preserveAspectRatio="none slice">
//   <circle cx="1" cy="1" r="1" fill="{INJECTED_COLOR}"/>
// </svg>
@function _mat-progress-bar-buffer($palette, $hue) {
  $color: mat-color($palette, $hue) + '';

  // We also need to escape the hash in hex colors,
  // otherwise IE will throw an XML error.
  @if str-index($color, '#') == 1 {
    $color: '%23' + str-slice($color, 2);
  }

  $result: '' +
    'data:image/svg+xml;charset=UTF-8,%3Csvg%20version%3D%271.1%27%20xmlns%3D%27http%3A%2F%2F' +
    'www.w3.org%2F2000%2Fsvg%27%20xmlns%3Axlink%3D%27http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%2' +
    '7%20x%3D%270px%27%20y%3D%270px%27%20enable-background%3D%27new%200%200%205%202%27%20xml%' +
    '3Aspace%3D%27preserve%27%20viewBox%3D%270%200%205%202%27%20preserveAspectRatio%3D%27none' +
    '%20slice%27%3E%3Ccircle%20cx%3D%271%27%20cy%3D%271%27%20r%3D%271%27%20fill%3D%27' +
    $color + '%27%2F%3E%3C%2Fsvg%3E';

  @return url($result);
}





@mixin mat-progress-spinner-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .mat-progress-spinner, .mat-spinner {
    path {
      stroke: mat-color($primary);
    }

    &.mat-accent path {
      stroke: mat-color($accent);
    }

    &.mat-warn path {
      stroke: mat-color($warn);
    }
  }
}

@mixin mat-progress-spinner-typography($config) { }





@mixin mat-radio-color($palette) {
  &.mat-radio-checked .mat-radio-outer-circle {
    border-color: mat-color($palette);
  }

  .mat-radio-inner-circle {
    background-color: mat-color($palette);
  }

  .mat-radio-ripple .mat-ripple-element {
    background-color: mat-color($palette, 0.26);
  }
}

@mixin mat-radio-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-radio-outer-circle {
    border-color: mat-color($foreground, secondary-text);
  }

  .mat-radio-disabled .mat-radio-outer-circle {
    border-color: mat-color($foreground, disabled);
  }

  .mat-radio-disabled {
    .mat-radio-ripple .mat-ripple-element, .mat-radio-inner-circle {
      background-color: mat-color($foreground, disabled);
    }

    .mat-radio-label-content {
      color: mat-color($foreground, disabled);
    }
  }

  .mat-radio-button {
    &.mat-primary {
      @include mat-radio-color($primary);
    }

    &.mat-accent {
      @include mat-radio-color($accent);
    }

    &.mat-warn {
      @include mat-radio-color($warn);
    }
  }
}

@mixin mat-radio-typography($config) {
  .mat-radio-button {
    font-family: mat-font-family($config);
  }
}





@mixin _mat-select-inner-content-theme($palette) {
  $color: mat-color($palette);

  .mat-select-trigger, .mat-select-arrow {
    color: $color;
  }

  .mat-select-underline {
    background-color: $color;
  }
}

@mixin mat-select-theme($theme) {
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  .mat-select-trigger,
  .mat-select-arrow {
    color: mat-color($foreground, hint-text);
  }

  .mat-select-underline {
    background-color: mat-color($foreground, divider);
  }

  .mat-select-disabled .mat-select-value,
  .mat-select-arrow,
  .mat-select-trigger {
    color: mat-color($foreground, hint-text);
  }

  .mat-select-content, .mat-select-panel-done-animating {
    background: mat-color($background, card);
  }

  .mat-select-value {
    color: mat-color($foreground, text);
  }

  .mat-select:focus:not(.mat-select-disabled) {
    &.mat-primary {
      @include _mat-select-inner-content-theme($primary);
    }

    &.mat-accent {
      @include _mat-select-inner-content-theme($accent);
    }
  }

  .mat-select:focus:not(.mat-select-disabled).mat-warn,
  .mat-select:not(:focus).ng-invalid.ng-touched:not(.mat-select-disabled) {
    @include _mat-select-inner-content-theme($warn);
  }
}

@mixin mat-select-typography($config) {
  .mat-select {
    font-family: mat-font-family($config);
  }

  .mat-select-trigger {
    font-size: mat-font-size($config, subheading-2);
  }
}





@mixin mat-sidenav-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  // We use invert() here to have the darken the background color expected to be used. If the
  // background is light, we use a dark backdrop. If the background is dark,
  // we use a light backdrop.
  $sidenav-backdrop-color: invert(mat-color($background, card, 0.6)) !default;
  $sidenav-background-color: mat-color($background, dialog) !default;
  $sidenav-container-background-color:  mat-color($background, background) !default;
  $sidenav-push-background-color: mat-color($background, dialog) !default;

  .mat-sidenav-container {
    background-color: $sidenav-container-background-color;
    color: mat-color($foreground, text);
  }

  .mat-sidenav {
    background-color: $sidenav-background-color;
    color: mat-color($foreground, text);

    &.mat-sidenav-push {
      background-color: $sidenav-push-background-color;
    }
  }

  .mat-sidenav-backdrop.mat-sidenav-shown {
    background-color: $sidenav-backdrop-color;
  }
}

@mixin mat-sidenav-typography($config) { }





@mixin _mat-slide-toggle-checked($palette, $thumb-checked-hue) {
  // Do not apply the checked colors if the toggle is disabled, because the specificity would be to high for
  // the disabled styles.
  &.mat-checked:not(.mat-disabled) {
    .mat-slide-toggle-thumb {
      background-color: mat-color($palette, $thumb-checked-hue);
    }

    .mat-slide-toggle-bar {
      background-color: mat-color($palette, $thumb-checked-hue, 0.5);
    }
  }
}

// Applies the specified colors to the slide-toggle ripple elements.
@mixin _mat-slide-toggle-ripple($palette, $ripple-unchecked-color, $ripple-checked-color) {
  &:not(.mat-checked) .mat-ripple-element {
    background-color: $ripple-unchecked-color;
  }
  .mat-ripple-element {
    background-color: $ripple-checked-color;
  }
}

@mixin mat-slide-toggle-theme($theme) {
  $is-dark: map_get($theme, is-dark);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  // Color hues based on the specs, which prescribe different hues for dark and light themes
  // https://material.google.com/components/selection-controls.html#selection-controls-switch
  $thumb-normal-hue: if($is-dark, 400, 50);
  $thumb-checked-hue: if($is-dark, 200, 500);
  $thumb-disabled-hue: if($is-dark, 800, 400);

  $bar-normal-color: mat-color($foreground, disabled);
  $bar-disabled-color: if($is-dark, rgba(white, 0.12), rgba(black, 0.1));

  // Ripple colors are based on the current palette and the state of the slide-toggle.
  // See https://material.google.com/components/selection-controls.html#selection-controls-switch
  $ripple-checked-opacity: 0.12;
  $ripple-unchecked-color: mat-color($foreground, base, if($is-dark, 0.12, 0.06));
  $ripple-primary-color: mat-color($primary, $thumb-checked-hue, $ripple-checked-opacity);
  $ripple-accent-color: mat-color($accent, $thumb-checked-hue, $ripple-checked-opacity);
  $ripple-warn-color: mat-color($warn, $thumb-checked-hue, $ripple-checked-opacity);

  .mat-slide-toggle {
    @include _mat-slide-toggle-checked($accent, $thumb-checked-hue);
    @include _mat-slide-toggle-ripple($accent, $ripple-unchecked-color, $ripple-accent-color);

    &.mat-primary {
      @include _mat-slide-toggle-checked($primary, $thumb-checked-hue);
      @include _mat-slide-toggle-ripple($accent, $ripple-unchecked-color, $ripple-primary-color);
    }

    &.mat-warn {
      @include _mat-slide-toggle-checked($warn, $thumb-checked-hue);
      @include _mat-slide-toggle-ripple($accent, $ripple-unchecked-color, $ripple-warn-color);
    }

  }

  .mat-disabled {
    .mat-slide-toggle-thumb {
      // The thumb of the slide-toggle always uses the hue 400 of the grey palette in dark or light themes.
      // Since this is very specific to the slide-toggle component, we're not providing
      // it in the background palette.
      background-color: mat-color($mat-grey, $thumb-disabled-hue);
    }
    .mat-slide-toggle-bar {
      background-color: $bar-disabled-color;
    }
  }

  .mat-slide-toggle-thumb {
    background-color: mat-color($mat-grey, $thumb-normal-hue);
  }

  .mat-slide-toggle-bar {
    background-color: $bar-normal-color;
  }
}

@mixin mat-slide-toggle-typography($config) {
  .mat-slide-toggle-content {
    @include mat-typography-level-to-styles($config, body-1);
  }
}





@mixin _mat-slider-inner-content-theme($palette) {
  .mat-slider-track-fill,
  .mat-slider-thumb,
  .mat-slider-thumb-label {
    background-color: mat-color($palette);
  }

  .mat-slider-thumb-label-text {
    color: mat-color($palette, default-contrast);
  }
}

@mixin mat-slider-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  $mat-slider-off-color: mat-color($foreground, slider-off);
  $mat-slider-off-focused-color: mat-color($foreground, slider-off-active);
  $mat-slider-disabled-color: mat-color($foreground, slider-off);
  $mat-slider-labeled-min-value-thumb-color: mat-color($foreground, base);
  $mat-slider-labeled-min-value-thumb-label-color: mat-color($foreground, slider-off);
  $mat-slider-focus-ring-color: mat-color($accent, default, 0.2);
  $mat-slider-focus-ring-min-value-color: mat-color($foreground, base, 0.12);
  $mat-slider-tick-color: mat-color($foreground, base, 0.7);
  $mat-slider-tick-size: 2px;

  .mat-slider-track-background {
    background-color: $mat-slider-off-color;
  }

  .mat-primary {
    @include _mat-slider-inner-content-theme($primary);
  }

  .mat-accent {
    @include _mat-slider-inner-content-theme($accent);
  }

  .mat-warn {
    @include _mat-slider-inner-content-theme($warn);
  }

  .mat-slider-focus-ring {
    background-color: $mat-slider-focus-ring-color;
  }

  .mat-slider:hover,
  .cdk-focused {
    .mat-slider-track-background {
      background-color: $mat-slider-off-focused-color;
    }
  }

  .mat-slider-disabled {
    .mat-slider-track-background,
    .mat-slider-track-fill,
    .mat-slider-thumb {
      background-color: $mat-slider-disabled-color;
    }

    &:hover {
      .mat-slider-track-background {
        background-color: $mat-slider-disabled-color;
      }
    }
  }

  .mat-slider-min-value {
    .mat-slider-focus-ring {
      background-color: $mat-slider-focus-ring-min-value-color;
    }

    &.mat-slider-thumb-label-showing {
      .mat-slider-thumb,
      .mat-slider-thumb-label {
        background-color: $mat-slider-labeled-min-value-thumb-color;
      }

      &.cdk-focused {
        .mat-slider-thumb,
        .mat-slider-thumb-label {
          background-color: $mat-slider-labeled-min-value-thumb-label-color;
        }
      }
    }

    &:not(.mat-slider-thumb-label-showing) {
      .mat-slider-thumb {
        border-color: $mat-slider-off-color;
        background-color: transparent;
      }

      &:hover,
      &.cdk-focused {
        .mat-slider-thumb {
          border-color: $mat-slider-off-focused-color;
        }

        &.mat-slider-disabled .mat-slider-thumb {
          border-color: $mat-slider-disabled-color;
        }
      }
    }
  }

  .mat-slider-has-ticks .mat-slider-wrapper::after {
    border-color: $mat-slider-tick-color;
  }

  .mat-slider-horizontal .mat-slider-ticks {
    background-image: repeating-linear-gradient(to right, $mat-slider-tick-color,
        $mat-slider-tick-color $mat-slider-tick-size, transparent 0, transparent);
    // Firefox doesn't draw the gradient correctly with 'to right'
    // (see https://bugzilla.mozilla.org/show_bug.cgi?id=1314319).
    background-image: -moz-repeating-linear-gradient(0.0001deg, $mat-slider-tick-color,
        $mat-slider-tick-color $mat-slider-tick-size, transparent 0, transparent);
  }

  .mat-slider-vertical .mat-slider-ticks {
    background-image: repeating-linear-gradient(to bottom, $mat-slider-tick-color,
        $mat-slider-tick-color $mat-slider-tick-size, transparent 0, transparent);
  }
}

@mixin mat-slider-typography($config) {
  .mat-slider-thumb-label-text {
    font: {
      size: mat-font-size($config, caption);
      weight: mat-font-weight($config, body-2);
    }
  }
}





@mixin mat-tabs-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $header-border: 1px solid mat-color($foreground, divider);

  .mat-tab-nav-bar,
  .mat-tab-header {
    border-bottom: $header-border;
  }

  .mat-tab-group-inverted-header {
    .mat-tab-nav-bar,
    .mat-tab-header {
      border-top: $header-border;
      border-bottom: none;
    }
  }

  .mat-tab-label:focus {
    background-color: mat-color($primary, lighter, 0.3);
  }

  .mat-ink-bar {
    background-color: mat-color($primary);
  }

  .mat-tab-label, .mat-tab-link {
    color: mat-color($foreground, text);

    &.mat-tab-disabled {
      color: mat-color($foreground, disabled-text);
    }
  }
}

@mixin mat-tabs-typography($config) {
  .mat-tab-group {
    font-family: mat-font-family($config);
  }

  .mat-tab-label, .mat-tab-link {
    font: {
      family: mat-font-family($config);
      size: mat-font-size($config, button);
      weight: mat-font-weight($config, button);
    }
  }
}






@mixin _mat-toolbar-color($palette) {
  background: mat-color($palette);
  color: mat-color($palette, default-contrast);
}

@mixin mat-toolbar-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-toolbar {
    background: mat-color($background, app-bar);
    color: mat-color($foreground, text);

    &.mat-primary {
      @include _mat-toolbar-color($primary);
    }

    &.mat-accent {
      @include _mat-toolbar-color($accent);
    }

    &.mat-warn {
      @include _mat-toolbar-color($warn);
    }
  }
}

@mixin mat-toolbar-typography($config) {
  .mat-toolbar,
  .mat-toolbar h1,
  .mat-toolbar h2,
  .mat-toolbar h3,
  .mat-toolbar h4,
  .mat-toolbar h5,
  .mat-toolbar h6 {
    @include mat-typography-level-to-styles($config, title);
    margin: 0;
  }
}





// TODO(crisbeto): these doesn't correspond to any typography levels
// and the width/height appear to be off from the spec.
$mat-tooltip-target-height: 22px;
$mat-tooltip-font-size: 10px;
$mat-tooltip-vertical-padding: ($mat-tooltip-target-height - $mat-tooltip-font-size) / 2;

@mixin mat-tooltip-theme($theme) {
  .mat-tooltip {
    background: mat-color($mat-grey, 700, 0.9);
  }
}

@mixin mat-tooltip-typography($config) {
  .mat-tooltip {
    font-family: mat-font-family($config);
    font-size: $mat-tooltip-font-size;
    padding-top: $mat-tooltip-vertical-padding;
    padding-bottom: $mat-tooltip-vertical-padding;
  }
}



@mixin mat-simple-snack-bar-theme($theme) { }

@mixin mat-simple-snack-bar-typography($config) {
  .mat-simple-snackbar {
    font: {
      family: mat-font-family($config);
      size: mat-font-size($config, body-1);
    }
  }

  .mat-simple-snackbar-action {
    line-height: 1;
    font: {
      family: inherit;
      size: inherit;
      weight: mat-font-weight($config, button);
    }
  }
}




// Includes all of the typographic styles.
@mixin angular-material-typography($config: mat-typography-config()) {
  @include mat-base-typography($config);
  @include mat-autocomplete-typography($config);
  @include mat-button-typography($config);
  @include mat-button-toggle-typography($config);
  @include mat-card-typography($config);
  @include mat-checkbox-typography($config);
  @include mat-chips-typography($config);
  @include mat-datepicker-typography($config);
  @include mat-dialog-typography($config);
  @include mat-grid-list-typography($config);
  @include mat-icon-typography($config);
  @include mat-input-typography($config);
  @include mat-menu-typography($config);
  @include mat-progress-bar-typography($config);
  @include mat-progress-spinner-typography($config);
  @include mat-radio-typography($config);
  @include mat-select-typography($config);
  @include mat-sidenav-typography($config);
  @include mat-slide-toggle-typography($config);
  @include mat-slider-typography($config);
  @include mat-tabs-typography($config);
  @include mat-toolbar-typography($config);
  @include mat-tooltip-typography($config);
  @include mat-list-typography($config);
  @include mat-option-typography($config);
  @include mat-simple-snack-bar-typography($config);
}


// Mixin that renders all of the core styles that are not theme-dependent.
@mixin mat-core() {
  // Provides external CSS classes for each elevation value. Each CSS class is formatted as
  // `mat-elevation-z$zValue` where `$zValue` corresponds to the z-space to which the element is
  // elevated.
  @for $zValue from 0 through 24 {
    .mat-elevation-z#{$zValue} {
      @include mat-elevation($zValue);
    }
  }

  @include angular-material-typography();
  @include mat-ripple();
  @include mat-option();
  @include mat-optgroup();
  @include cdk-a11y();
  @include cdk-overlay();
}

// Mixin that renders all of the core styles that depend on the theme.
@mixin mat-core-theme($theme) {
  @include mat-ripple-theme($theme);
  @include mat-option-theme($theme);
  @include mat-optgroup-theme($theme);
  @include mat-pseudo-checkbox-theme($theme);

  // Wrapper element that provides the theme background when the
  // user's content isn't inside of a `md-sidenav-container`.
  .mat-app-background {
    $background: map-get($theme, background);
    background-color: mat-color($background, background);
  }

  // Marker that is used to determine whether the user has added a theme to their page.
  .mat-theme-loaded-marker {
    display: none;
  }
}












@mixin mat-expansion-panel-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .mat-expansion-panel {
    background: mat-color($background, card);
    color: mat-color($foreground, base);
  }

  .mat-action-row {
    border-top-color: mat-color($foreground, divider);
  }

  .mat-expansion-panel-header:focus, 
  .mat-expansion-panel-header:hover {
    background: mat-color($background, hover);
  }
  .mat-expansion-panel-header-title {
    color: mat-color($foreground, text);
  }

  .mat-expansion-panel-header-description {
    color: mat-color($foreground, secondary-text);
  }

  .mat-expansion-indicator::after {
    color: mat-color($foreground, secondary-text);
  }
}


















// Create a theme.
@mixin angular-material-theme($theme) {
  @include mat-core-theme($theme);
  @include mat-autocomplete-theme($theme);
  @include mat-button-theme($theme);
  @include mat-button-toggle-theme($theme);
  @include mat-card-theme($theme);
  @include mat-checkbox-theme($theme);
  @include mat-chips-theme($theme);
  @include mat-datepicker-theme($theme);
  @include mat-dialog-theme($theme);
  @include mat-expansion-panel-theme($theme);
  @include mat-grid-list-theme($theme);
  @include mat-icon-theme($theme);
  @include mat-input-theme($theme);
  @include mat-list-theme($theme);
  @include mat-menu-theme($theme);
  @include mat-progress-bar-theme($theme);
  @include mat-progress-spinner-theme($theme);
  @include mat-radio-theme($theme);
  @include mat-select-theme($theme);
  @include mat-sidenav-theme($theme);
  @include mat-slide-toggle-theme($theme);
  @include mat-slider-theme($theme);
  @include mat-tabs-theme($theme);
  @include mat-toolbar-theme($theme);
  @include mat-tooltip-theme($theme);
  @include mat-simple-snack-bar-theme($theme);
}



////****edit*****///

   .mat-input-container
    {
    width: 100%;
    outline:none;
 

    .mat-input-wrapper {
        padding-bottom:0px!important;
        background: red;
        display: block;
        width: 100%;
        height:27px;
        font-size: 14px;
        color: #555;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 4px;
        .mat-input-underline 
        {
          height:0px!important;
        }

      }

      .mat-input-infix {
        padding:0px!important;
        border-top: 0px!important;
      }
      
      .mat-input-underline
      {
        background:none;

       .mat-input-ripple 
        {
          background-color:white!important;
        
        }
        
      }
      .mat-datepicker-toggle
      {
        position: relative;
        z-index: 999;
      }
      .mat-input-flex
      {
        height:25px!important;
      }
      
      .dateActive
      {
        border:1px solid #005eb8;
      }
      .dateInactive
      {
        border:1px solid #f44336;
      }
     
 
 }

    .cdk-overlay-container
    {
      .cdk-overlay-pane, .mat-calendar
      {
        width:232px!important;
      }
      .mat-calendar-controls
      {
        padding: 0;
      }
      
      .mat-datepicker-content
      {
        border-radius:7px;
        .mat-calendar-header
        {
          background:#74808d;
          color: white;
          font-weight:bold;
          padding: 0;
        }
        .mat-calendar-arrow
        {
          border-top-color: white;
        }
        .mat-calendar-table-header th
        {
          padding-bottom:3px;
          font-weight:bold;
          color: #005EB8;
        }
        .mat-calendar-content
        {
          margin-top:4px!important;
        }
        .mat-calendar-body-label
        {
          font-weight: bold;
          color: #74808d;
          font-size: 12px;
        }
        .mat-calendar-body-today.mat-calendar-body-selected
        {
          box-shadow: none;
          color: white;
        }
        .mat-calendar-body-cell
        {
          font-size: 11px;
        }
        .mat-calendar-body-selected
        {
          color: white!important;
        }

      }



      
   }
