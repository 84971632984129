.clear {
  clear: both;
  margin: 0px;
  padding: 0px;
}
.clearfix::after {
  clear: both !important;
}
b,
strong {
  font-weight: 700;
}
button {
  outline: none !important;
}
button:focus {
  box-shadow: none !important;
}
.flLeft {
  float: left;
}
.flRight {
  float: right !important;
}
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12 {
  margin: 0;
  padding: 0;
}
.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
@media (max-width: 991px) {
  .d-none .d-sm-block {
    display: none !important;
  }
  .d-block .d-sm-none {
    display: block !important;
  }
}
h1,
h2 {
  color: $twilightBlueTwo;
  font-family: $kpmgFont;
  font-size: 42px;
  font-weight: $font300;
  letter-spacing: 1px;
  line-height: 30px;
  margin: 20px 0;
}
h3 {
  color: $twilightBlueTwo;
  font-size: 16px;
  font-weight: $fontbold;
  line-height: 1.31;
  text-align: $alignLeft;
}
.svgIm {
  float: left;
  height: 22px;
  margin-right: 2px;
  padding: 0px 3px 0 3px;
}
.setIm {
  float: left;
  height: 22px;
  margin-left: 0px;
  padding: 8px 6px 0 7px;
}
.OptionPostion {
  margin-left: 5px;
}
.oceanBlue {
  fill: $oceanBlue;
}
.steelgray {
  fill: $steelgrey;
}
.whiteColor {
  fill: $colorWhite;
}
.darkishPurple {
  fill: $darkishPurple;
}
.greenish {
  fill: $greenish;
}
.deepgreen {
  fill: $deepgreen;
}
.yellowOrange {
  fill: $yellowOrange;
}
.deepRed {
  fill: $deepRed;
}
.lightblue {
  fill: $lightblue;
}
.lightorange {
  fill: $lightorange;
}
.greyColor {
  fill: $fontcolorgrey;
}
.greyColor1 {
  fill: $grey1;
}
.greyColor2 {
  fill: $grey2;
}
.greyColor3 {
  fill: $grey3;
}
.greyColor4 {
  fill: $grey4;
}
.greyColor6 {
  fill: $grey6;
}
.greyColor9 {
  fill: $grey9;
}
.lipstickColor {
  fill: $lipstick;
}
.ceruleanColor {
  fill: $ceruleanBlue;
}
.deepgreyColor {
  fill: $fontDeepgrey;
}
.tealBlue {
  fill: $tealBlue;
}
.lightgreyColor {
  fill: $lightgrey;
}
.deepGreenColor {
  fill: $deepGreen;
}
.lightSkyColor {
  fill: $lightSky;
}
.twilightBlue {
  fill: $twilightBlue;
}
.yellowColor {
  fill: $yellow;
}
.cnbArrow {
  fill: rgba(255, 255, 255, 0.3);
}
// *::-ms-backdrop,
// .arrange_collection_today {
//   margin-top: -60px;
// }
.fontArialBold {
  font-family: $bodyFont;
  font-weight: bold;
}

.dropdown-menu {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  & > li > a {
    @extend .fontArialBold;
    font-size: $fontsize14;
    font-size: 14px;
    padding: 0px 20px;
    display: block;
    padding: 3px 20px;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
    &:hover {
      background: $oceanBlue;
      color: $colorWhite;
    }
  }
}
.modal-dialog.modalPopUp {
  position: absolute;
  width: 37.5%;
  top: 27%;
  left: 30%;
}
.helpModal.modal-dialog {
  max-width: 100% !important;
  width: 67%;
}
.modal-dialog.modal-filter{
  max-height: 90%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%) !important;
}
@media screen and (max-width: 540px) {
  .modal-dialog.modalPopUp {
    width: 90% !important;
    left: 2.4% !important;
  }
}
@media (min-width: 541px) and (max-width: 767px) {
  .modal-dialog.modalPopUp {
    width: 92% !important;
    left: 1.2% !important;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .modal-dialog.modalPopUp {
    width: 50% !important;
    left: 25% !important;
  }
}
@media screen and (max-width: 992px) {
  .modal-dialog.modalPopUp {
    top: 25%;
  }
}

@media screen and (max-width: 992px) {
  .helpModal.modal-dialog {
    width: 90% !important;
    top: 5% !important;
    z-index: 99;
  }
}
